
<template>
  <transition name="fade">
    <div class="full-page-modal" v-if="show">
      <div class="full-page-modal__backdrop" @click="internalClose"/>

      <div class="full-page-modal__dialog">
        <div class="full-page-modal__header">
          <h4>{{ title }}</h4>
          <button class="full-page-modal__close" @click="internalClose">×</button>
        </div>
        <div class="full-page-modal__header-slot">
          <slot name="header"/>
        </div>

        <div class="full-page-modal__body">
          <slot name="body"/>
        </div>

        <div class="full-page-modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FullPageModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    closeCallback: {
      type: Function,
      default: function () {
        this.show = false
        document.querySelector('body').classList.remove('overflow-hidden')
      }
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    closeModal () {
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openModal () {
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
    },
    internalClose () {
      if (!this.closeCallback) {
        this.closeModal()
      } else {
        this.closeCallback()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
