import { render, staticRenderFns } from "./ViewCompanies.vue?vue&type=template&id=011f5895&scoped=true&"
import script from "./ViewCompanies.vue?vue&type=script&lang=js&"
export * from "./ViewCompanies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011f5895",
  null
  
)

export default component.exports