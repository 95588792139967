<template>
  <div>
    <ViewCompanyModal ref="ViewCompanyModal"></ViewCompanyModal>
    <confirmation ref="Confirmation"></confirmation>
    <alert ref="Alert"></alert>
    <user-request-modal ref="UserRequestModal"></user-request-modal>

    <div style="flex-grow:1;">
      <h1>Companies</h1>
    </div>

    <datatable-heading
      ref="DatatableHeading"
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
      <template v-slot:button>
        <b-button
          class="top-right-button mr-2"
          v-b-modal.modalright
          variant="primary"
          size="lg"
          @click="openCompanyModal()"
        >
          Add Company
        </b-button>
        <b-button
          v-b-modal.modalright
          variant="primary"
          size="md"
          style="font-size: 18px"
          class="simple-icon-trash"
          v-show="isTrashVisible"
          @click="onContextMenuAction('remove')"
        ></b-button>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="companyID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              :sort-order="sortOrder"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('remove')">
        <i class="simple-icon-trash" />
        <span>Delete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import alert from '../../../components/Common/Alert.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl, CompanyContext } from '../../../constants/config'

import DatatableHeading from '../../../containers/datatable/DatatableHeading.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import CompanyMixin from '../../../mixins/CompanyMixin'
import LoginMixins from '../../../mixins/LoginMixins'
// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'
import { mutations } from '../../../store/FilterMenu'

import userRequestModal from '../../../components/Modals/UserRequestModal.vue'
import bus from '../../../main'
import Confirmation from '../../../components/Common/Confirmation.vue'
import ViewCompanyModal from '../../../components/Modals/ViewCompanyModal.vue'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Alert: alert,
    'user-request-modal': userRequestModal,
    confirmation: Confirmation,
    ViewCompanyModal: ViewCompanyModal
  },
  async created () {
    const { loginid = null } = this.$route.query
    this.apiBase = `${BeaconapiUrl}/api/logins/${loginid}/company/table`
    this.userId = loginid
  },
  mixins: [CompanyMixin, LoginMixins],
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    }
  },
  data () {
    var _idfield = 'companyID'
    return {
      json_data: [],
      sortOrder: [],
      apiBase: '',
      sort: '',
      page: 1,
      perPage: 25,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      userId: null,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: 'companyLegalName',
          sortField: 'companyLegalName',
          title: 'Company Legal Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%',
          filterType: 'text'
        },
        {
          name: 'dbaCompanyName',
          sortField: 'dbaCompanyName',
          title: 'DBA Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%',
          filterType: 'text'
        },
        {
          name: 'physicalAddress',
          sortField: 'physicalAddress',
          title: 'Physical Address',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%',
          filterType: 'text'
        },
        {
          name: 'locationTypeDesc',
          sortField: 'locationTypeDesc',
          title: 'Location Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%',
          filterType: 'text'
        }
      ]
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected-row'
      } else if (dataItem.isTime) {
        outputclass = 'time-row'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
          x => x !== itemId
        )
        localStorage.setItem(CompanyContext, null)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
        localStorage.setItem(CompanyContext, itemId)
        bus.$emit('buildcompanycontext')
        bus.$emit('opencompanysubmenu')
      }

      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'remove': {
          this.$refs.Confirmation.show({
            title: 'Delete Company',
            message: `Are you sure you want to delete ${this.selectedItems.join(',')}?`,
            okButton: 'Delete'
          }).then(async result => {
            if (result) {
              this.setInfoMessage('')
              await this.deleteItems()
            }
          })
          break
        }
        default:
          break
      }
    },
    openCompanyModal () {
      this.$refs.ViewCompanyModal.show({
        saveFunction: () => {
          this.refreshVueTable()
        }
      })
    },
    // delete one or multiple items
    async deleteItems () {
      // loop through all selected items from the grid
      await this.deleteCompanies(this.userId, this.$refs.vuetable.selectedTo)
        .then(() => {
          this.refreshVueTable()
        })
    }
  }
}
</script>
<style scoped>
</style>
