<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      statusCode: null
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setWarningMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage',
      'setModalDangerMessage'
    ]),
    async assignCompanies (userId, companies) {
      await axios
        .post(`${BeaconapiUrl}/api/logins/${userId}/assign-companies?userid=${userId}`, companies)
        .then(result => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data)
          }
        })
        .catch(error => {
          // TZ - If we have a server side error and return BadRequest, then, the .then promise clause above will not fire.
          // We will go directly into this catch clause.
          this.statusCode = error.response.status
          this.setDangerMessage(error)
        })
    },
    async deleteCompanies (userId, companies) {
      await axios
        .delete(`${BeaconapiUrl}/api/logins/${userId}/companies?userid=${userId}`, { data: companies })
        .then(result => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data)
          }
        })
        .catch(error => {
          // TZ - If we have a server side error and return BadRequest, then, the .then promise clause above will not fire.
          // We will go directly into this catch clause.
          this.statusCode = error.response.status
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
